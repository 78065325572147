<template>
  <div class="content">
    <div class="heroblue">
      <div>
        <h1>
          Give sales reps the right content, for the right customer, <br>at the right time.
        </h1>
        <router-link :to="{ name: 'register', params: { r: '9cb07194ccc2' }}">
          <div class="signupbox"> Sign up free </div>
        </router-link>
        <div style="text-align:center;margin:10px;color:#fff;">
          <router-link to="/login">Already have an account? Login</router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div style="text-align: center;margin:auto;">
        <img :src="require('@/assets/radi8-newsfeed.png')" alt="Radi8 software helps sales and marketing managers collect and share news." />
      </div>
      <div class="blade1">
        Radi8 is an app that helps marketing managers build a library of sales enablement materials, 
        so sales reps can easily find and share the right content, with the right customer, at the right time.
      </div>

    </div>
    <div class="row">
      <div class="column">
        <div class="homecard">
          <div class="cardicon">
            <img :src="require('@/assets/ic_videos.png')" />
          </div>
          <div class="h3">
            Save news stories, links, and docs along with private notes for reference
          </div>
        </div>
      </div>
      <div class="column">
        <div class="homecard">
          <div class="cardicon">
            <img :src="require('@/assets/ic_polls.png')" />
          </div>
          <div class="h3">
            Add tags and save search filters to find and share content faster
          </div>
        </div>
      </div>
      <div class="column">
        <div class="homecard">
          <div class="cardicon">
            <img :src="require('@/assets/ic_goals.png')" />
          </div>
          <div class="h3">
            Let sales and marketing managers add content for you to read and share
          </div>
        </div>
      </div>
      <div class="column">
        <div class="homecard">
          <div class="cardicon">
            <img :src="require('@/assets/ic_assets.png')" />
          </div>
          <div class="h3">
            Share content directly to your social media networks or email links to customers
          </div>
        </div>
      </div>
    </div>
    <div class="homequote">
      Radi8 uses AI to match customers with content, when they're ready to engage with your sales team.
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";

export default {
  name: "Home",
  mounted: function() {
    if (isLoggedIn()) {
      this.$router.push("dashboard");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    }
  },
  components: {},
  data () {
      return {
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  // background: #02536d ;
  min-height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 2.5rem;
    line-height: 2.75rem;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #02536d;
      text-align: center;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

