export * from './user.service';
<template>
    <div id="app">
        <Header :key="$route.name"/>
        <router-view/>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/includes/Header.vue'
import Footer from '@/components/includes/Footer.vue'

export default {
	name      : process.env.VUE_APP_TITLE,
	data () {
		return {}
	},
	components: {
		Header,
		Footer
	}
}
</script>

<style lang="scss">
	@import '@/scss/main.scss';
</style>
