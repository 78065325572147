import { authHeader } from '../_helpers';

export const userService = {
    register,
    login,
    verify,
    sendverificationid,
    logout,
    getMe,
    updateMe,
    getAll,
    getUser,
    checkEmail
};

function register(first_name, last_name, email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ first_name, last_name, email, password })
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/auth/register"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // registration successful if there's a user in the response
            if (data.user) {
                // remove user from local storage to log user out
                localStorage.removeItem('user');
            }
            return data.user;
        });
}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/auth/login"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // login successful if there's a user in the response
            if (data.user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                data.user.authdata = data.token;
                localStorage.setItem('user', JSON.stringify(data.user));
            }

            return data.user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    // return fetch(`${config.apiUrl}/api/v1/users`, requestOptions).then(handleResponse);
    var endpoint = process.env.VUE_APP_API_URL + `/api/v1/users` ;
    return fetch(endpoint, requestOptions).then(handleResponse);
}

function getMe() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/user/me"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // there's a user in the response
            if (data.user) {
                return data.user;
            }

            return "";
        });
}

function updateMe(first_name, last_name) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ first_name, last_name })
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/user/me"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // update successful if there's a user in the response
            if (data.user) {
                // Get the existing data
                var existingUser = localStorage.getItem('user');

                // If no existing data, create an array
                // Otherwise, convert the localStorage string to an array
                existingUser = existingUser ? JSON.parse(existingUser) : {};

                // Add new data to localStorage Array
                existingUser.first_name = data.user.first_name;
                existingUser.last_name = data.user.last_name;

                // Save back to localStorage
                localStorage.setItem('user', JSON.stringify(existingUser));

                return data.user
            } else {
                return ''
            }
        });
}

function getUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + id
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // there's a user in the response
            if (data.user) {
                return data.user;
            }

            return "";
        });
}

function verify(verificationID) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/auth/verify/" + verificationID
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // there's a user in the response
            if (data.user) {
                return data.user;
            }

            return "";
        });
}


function sendverificationid(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/auth/resend-verification"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // there's a success in the response
            if (data.message == "success") {
                return data.message;
            }

            return "";
        });
}


function checkEmail(em) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/check/" + em
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // there's a user in the response
            if (data.user) {
                return data.user;
            }

            return "";
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        var data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
