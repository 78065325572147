import { authHeader } from '../_helpers';

export const accountService = {
    createAccount,
    readAccount,
    updateAccount,
    deleteAccount,
    readAccounts
};
function updateAccount() {}
function deleteAccount() {}

function createAccount(account_name) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ account_name })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts"
    console.log("UID: " + uid)

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // account creation is successful if there's an account in the response
            if (data.account) {
                // add account to local storay
                localStorage.removeItem('account');
            }
            return data.account;
        });
}

function readAccount(account_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + account_id
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // login successful if there's a user in the response
            if (data.user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                data.user.authdata = data.token;
                localStorage.setItem('user', JSON.stringify(data.user));
            }

            return data.user;
        });
}

function readAccounts() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // accounts array in the response
            if (data.accounts) {
                // store accounts in local storage 
                localStorage.setItem('accounts', JSON.stringify(data.accounts));
            }

            return data.accounts;
        });

}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

