<template>
  <footer style="padding:20px;font-size: small;">
    <div>
      <p>
        &copy;2016-2024 Radi8 <sup>®</sup> - A division of Custany, LLC
      </p> 
      <p>
        Contact us on Twitter <a href="https://twitter.com/Radi8HQ" target="_blank">@Radi8HQ</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      sociallinks: "Facebook Twitter LinkedIn"
    };
  }
};
</script>

<style scoped lang="scss">
footer {
  margin: 100px 0 0 0;
  padding: 0;
  text-align: center;
}
footer {
  & > ul {
    padding: 0;
    & > li {
      display: inline;
      padding: 0 10px;
      font-size: 0.8em;
    }
  }
}
</style>
