<template>
  <div class="content">
    <div class="heroshort">
      <div>
        <h1>
          Dashboard
        </h1>
      </div>
    </div>
    <div style="text-align:center;padding:20px;">
      <h2>Thank you for your interest in Radi8</h2>
      <p>
        We are currently in Private Beta with a limited number of users 
      </p>
      <p>
        You will get a notification when your account is ready
      </p>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { userService } from "../_services/user.service"

export default {
  name: "Home",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboard");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    }
  },
  components: {},
  data () {
      return {
        user: ''
      }
  },
  created () {
    userService.getMe().then(data => this.user = data)
  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #007cee;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

