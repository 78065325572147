<template>
  <div class="content bg8">
    <div class="heroshort yellow">
      <div>
        <h1>Login</h1>
      </div>
    </div>
    <div class="login">
      <form @submit.prevent="handleSubmit">
          <div class="form-grp">
              <input type="text" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" placeholder="Email"/>
              <div v-show="submitted && !email" class="invalid-feedback">Email is required</div>
          </div>
          <div class="form-grp">
              <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" placeholder="Password" />
              <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
          </div>
          <div class="form-grp">
              <button class="btn btn-primary" :disabled="loading">Login</button>
              <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>
          <div v-if="error" class="alert alert-danger">{{error}}</div>
          <div v-show="getverifiedlink">
              <p>
                  Can't find your verification id?<br />
                  <router-link to="/resend-verification-id">Resend Verification ID</router-link>
              </p>
          </div>
          <div v-show="!getverifiedlink">
              <p>
                  Don't have an account?
                  <router-link :to="{ name: 'register', params: { r: '9cb07194ccc2' }}">Register free</router-link>
              </p>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { userService } from '../_services';

export default {
    data () {
        return {
            email: '',
            password: '',
            getverifiedlink: false,
            submitted: false,
            loading: false,
            returnUrl: '',
            error: ''
        }
    },
    created () {
        // reset login status
        userService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.$route.query.returnUrl || '/';
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        handleSubmit (e) {
            this.submitted = true;
            const { email, password } = this;

            // stop here if form is invalid
            if (!(email && password)) {
                return;
            }

            this.loading = true;
            userService.login(email, password)
                .then(
                    // eslint-disable-next-line no-unused-vars
                    user => router.push(this.returnUrl),
                    error => {
                        this.error = error ;
                        if (this.error == "Account not verified") {
                          this.getverifiedlink = true ;
                        } else {
                            this.error = "Login failed"
                        }
                        this.loading = false ;
                    }
                );
        }
    }
};
</script>

<style lang="scss" scoped>
</style>