<template>
  <div class="content">
    <div class="heroshort">
      <div>
        <h1>
          Profile
        </h1>
      </div>
    </div>
    <div>
      <div class="login">
        <form @submit.prevent="handleSubmit">
            <div class="form-grp">
                <label for="first_name">First Name</label>
                <input type="text" v-model="first_name" name="first_name" />
            </div>
            <div class="form-grp">
                <label for="last_name">Last Name</label>
                <input type="text" v-model="last_name" name="last_name" />
            </div>
            <div class="form-grp">
                <label for="email">Email</label>
                <span>{{email}}</span>
            </div>
            <div class="form-group">
                <button class="btn btn-primary" :disabled="loading">Update</button>
                <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { userService } from "../_services/user.service"

export default {
  name: "Home",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboard");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
        // eslint-disable-next-line no-unused-vars
        handleSubmit (e) {
            this.submitted = true;
            const { first_name, last_name } = this;

            this.loading = true;
            userService.updateMe(first_name, last_name)
                .then(
                    // eslint-disable-next-line no-unused-vars
                    user => {
                      this.error = 'Profile updated successfully';
                      this.loading = false
                    },
                    error => {
                        this.error = error;
                        this.loading = false;
                    }
                );
        }    
  },
  components: {},
  data () {
      return {
        first_name: '',
        last_name: '',
        email: '',
        submitted: false,
        loading: false,
        returnUrl: 'profile',
        error: ''
      }
  },
  created () {
    userService.getMe().then(data => {
      this.first_name = data.first_name
      this.last_name = data.last_name
      this.email = data.email
    })
  }
};
</script>

<style lang="scss" scoped>
.login {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  & input {
    background: #eee ;
  }
}

label {
  display: block;
  margin-top: 10px;
  display: none;
}

.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #007cee;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

