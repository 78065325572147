<template>
  <div class="content">
    <div class="heroshort yellow">
      <div>
        <h1>Re-send Verification ID</h1>
      </div>
    </div>
    <div class="verify">
      <form @submit.prevent="handleSubmit">
          <div class="form-grp">
              <label for="email">Enter your email address:</label>
              <input type="text" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" />
              <div v-show="submitted && !email" class="invalid-feedback">Email is required</div>
          </div>
          <div class="form-grp">
              <button class="btn btn-primary" :disabled="loading">Email New Verification ID</button>
              <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>
          <div v-if="error" class="alert alert-danger">{{error}}</div>
          <div>
              <p>
                  Don't have an account?
                  <router-link :to="{ name: 'register', params: { r: '9cb07194ccc2' }}">Register free</router-link>
              </p>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { userService } from '../_services';

export default {
    data () {
        return {
            email: '',
            submitted: false,
            loading: false,
            returnUrl: '',
            error: ''
        }
    },
    created () {
        // reset login status
        userService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.$route.query.returnUrl || '/login';
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        handleSubmit (e) {
            this.submitted = true;
            const { email } = this;

            // stop here if form is invalid
            if (!(email)) {
                return;
            }

            this.loading = true;
            userService.sendverificationid(email)
                .then(
                    // eslint-disable-next-line no-unused-vars
                    message => router.push(this.returnUrl),
                    error => {
                        this.error = error;
                        this.loading = false;
                    }
                );
        }
    }
};
</script>

<style lang="scss" scoped>
.verify {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  & input {
    background: #eee ;
  }
}

.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}
label {
  display: block;
  margin-top: 10px;
  display: none;
}
</style>
