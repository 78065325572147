<template>
  <div class="content bg8">
    <div class="heroshort blue">
      <div>
        <h1>
          Radiate What Matters
        </h1>
      </div>
    </div>
    <div style="padding:20px;">
      <div style="max-width:800px;">
        <h2 style="margin-top:50px;">
          Our Mission
        </h2>
        <p>
          <strong>Share news with the right customer, at the right time.</strong><br>
          Companies spend a lot of time and money sharing news that customers don't care about.
          That's because your customer has to be in the right state of mind to receive the news.
          But when sales reps are engaged with customers, and they're in the right state of mind, you can't find the right news or docs to share.
          We are on a mission to change that. By creating a library of company news and docs that's easy to tag and search,
          you will always have great news to share, with the right people, at the right time. We combine your great content with
          machine learning to help you find the best news to share, at the right time.
        </p>
        
        <h2 style="margin-top:50px;">
          Our Values
        </h2>
        <p>
          <strong>Transparency, Humility and Action</strong><br>
          We are stronger when we help each other—and everyone needs help.
          To that end we believe being humble about our abilities and transparent about our challenges
          is critical to building strong relationships with our team, and with our customers.
          We also value action. Ideas are important, but action is the only thing that can make them real.
          Transparency, humility and action are the keys to creating an environment where anyone can achieve anything, and reach out for help, or lend a hand, as necessary.
        </p>

        <h2 style="margin-top:50px;">
          Our Team (100% Remote)
        </h2>
        <p>
          We are a team from across the globe. Are you interested in helping companies get organized, aligned and moving fast? 
          We are hiring freelance writers, videographers, graphic designers, full-stack web3 developers, full-stack javascript developers and social media marketers.
          <router-link to="contact">Contact us</router-link>
        </p>
        <p>
          Connect with us on LinkedIn <a href="https://www.linkedin.com/company/radi8hq" target="_blank">Radi8hq</a>
          or on Twitter <a href="https://twitter.com/radi8hq" target="_blank">@Radi8HQ</a>
        </p>           
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";

export default {
  name: "Home",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboard");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    }
  },
  components: {},
  data () {
      return {
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #007cee;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

