import { router } from "../_helpers/router";
import decode from "jwt-decode";

export function login() {
  router.go("/login");
}

export function logout() {
  clearIdToken();
  clearAccessToken();

  router.go("/login");
}

export function requireAuth(to, from, next) {
  if (!isLoggedIn()) {
    next({
      path: "/",
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
}

export function getUser() {
  return JSON.parse(localStorage.getItem('user'));
}

export function getAccessToken() {
  let user = JSON.parse(localStorage.getItem('user'));
  try {
    return user.authdata;
  } catch {
    return ""
  }
}

// Get and store access_token in local storage
export function setAccessToken() {
  let accessToken = getParameterByName("token");
  localStorage.setItem("user.authdata", accessToken);
}

// Get and store id_token in local storage
export function setIdToken() {
  let idToken = getParameterByName("token");
  localStorage.setItem("user.authdata", idToken);
}

export function isLoggedIn() {
  const Token = getAccessToken();
  return !!Token && !isTokenExpired(Token);
}

function clearIdToken() {
  localStorage.removeItem("user");
}

function clearAccessToken() {
  localStorage.removeItem("user.authdata");
}

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
  let match = RegExp("[#&]" + name + "=([^&]*)").exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  const date = new Date();
  if (!token.exp) {
    return date;
  }

  date.setUTCSeconds(token.exp);
  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}
