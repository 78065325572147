<template>
  <div class="content bg8">
    <div class="heroshort yellow">
      <div>
        <h1>
          Create a free account
        </h1>
      </div>
    </div>
    <div class="register">
      <form @submit.prevent="handleSubmit">
          <div class="form-grp">
              <input type="text" v-model="first_name" name="first_name" placeholder="First Name" />
          </div>
          <div class="form-grp">
              <input type="text" v-model="last_name" name="last_name" placeholder="Last Name" />
          </div>
          <div class="form-grp">
              <input type="text" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" placeholder="Email" />
              <div v-show="submitted && !email" class="invalid-feedback">Email is required</div>
          </div>
          <div class="form-grp">
              <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" placeholder="Password" />
              <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
          </div>
          <div class="form-group">
              <button class="btn btn-primary" :disabled="loading">Register</button>
              <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>
          <div v-if="error" class="alert alert-danger">{{error}}</div>
      </form>
    </div>
    <p align="center">
      Already have an account?
      <router-link to="/login">Login</router-link>
    </p>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { userService } from '../_services';

export default {
    data () {
        return {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            submitted: false,
            loading: false,
            returnUrl: '',
            error: ''
        }
    },
    props: ['r'],
    created () {
      // reset login status
      userService.logout();

      // if they didn't get here from a good link send them home
      if (this.r != '9cb07194ccc2') {
        this.$router.push({ name: 'about', query: {r: 'd' }});
        return
      }

    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        handleSubmit (e) {
            this.submitted = true;
            const { first_name, last_name, email, password } = this;

            // stop here if form is invalid
            if (!(email && password)) {
                return;
            }

            this.loading = true;

            userService.checkEmail(email)
              .then(response => {
                return response ;
              })
              .then(res => {
                if (res != "") {
                  this.loading = false ;
                  this.error = "You're already registered, please login."
                  return ;
                }
                userService.register(first_name, last_name, email, password)
                    .then(
                        // eslint-disable-next-line no-unused-vars
                        user => router.push("/verify"),
                        error => {
                            this.error = error;
                            this.loading = false;
                        }
                    );
              })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
