<template>
  <header>
    <div id="logo">
      <router-link to="/">
        <img
          :src="require('@/assets/radi8-logo-orange.png')"
          alt="Radi8"
        />
      </router-link>
    </div>
    <div id="mobilenav" class="menuicon" @click="toggle = !toggle">
      <div class="bar1" :class="{ change: toggle }"></div>
      <div class="bar2" :class="{ change: toggle }"></div>
      <div class="bar3" :class="{ change: toggle }"></div>
    </div>
    <transition name="dropme">
      <div id="nav" :class="{ on: toggle }" v-if="toggle">
        <ul>
          <router-link tag="li" v-if="!isLoggedIn()" to="/">Home</router-link>
          <router-link tag="li" v-if="isLoggedIn()" to="/dashboard">Dashboard</router-link>
          <router-link tag="li" v-if="isLoggedIn()" to="/profile">Profile</router-link>
          <router-link tag="li" v-if="isLoggedIn()" to="/accounts">Accounts</router-link>
          <router-link tag="li" v-if="!isLoggedIn()" to="/about">About Radi8</router-link>
          <li v-if="isLoggedIn()" @click="handleLogout()">Logout</li>
          <router-link tag="li" :to="{ name: 'register', params: { r: '9cb07194ccc2' }}" v-if="!isLoggedIn()">Register</router-link>
          <router-link tag="li" to="/login" v-if="!isLoggedIn()" @click="handleLogin()">Login</router-link>
        </ul>
      </div>
    </transition>
  </header>
</template>

<script>
import { isLoggedIn, login, logout } from "../../utils/auth";

export default {
  name: "app-nav",
  data() {
    return {
      toggle: false
    };
  },
  methods: {
    handleLogin() {
      login();
    },
    handleLogout() {
      logout();
    },
    isLoggedIn() {
      return isLoggedIn();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/colors";
header {
  padding: 20px 0;
  position: relative;
  min-height: 40px;
  max-width: 1200px;
  margin: 0 20px;
  @media only screen and (min-width: 1240px) {
    margin: auto;
  }
  @media only screen and (max-width: 440px) {
    margin: 0;
  }
  text-align: left;
  & > #logo {
    display: inline-block;
    padding-left:10px;
    font-size: 2em;
    line-height: 1em;
    & > a {
      text-decoration: none;
      border-bottom: 0;
      color: #000;
      & > img {
        height: 40px;
      }
    }
  }
  & a {
    font-weight: bold;
    color: $color-main;
    text-decoration: none;
    margin: 0 8px;
    &.router-link-exact-active {
      color: darken($color-accent, 5%);
      border-bottom: 4px dotted $color-accent;
    }
  }
  & > #mobilenav {
    float: right;
    padding: 0 20px 0 0;
    margin: -5px 0 0 0;
  }
  & > #nav {
    z-index: 999;
    position: absolute;
    top: 53px;
    right: 10px;
    margin-top: 10px;
    width: 300px;
    display: none;
    border-top: 4px solid $color-accent;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    @media only screen and (max-width: 600px) {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      box-shadow: none;
    }
    & > ul {
      width: auto;
      padding: 0;
      margin: 0;
      & > li {
        display: block;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;
        margin: 0;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 2.5em;
        background: #fff;
        border-bottom: 1px solid #eee;
        //border-left: 4px solid #ddd ;
        //border-right: 4px solid #ddd ;
        &.router-link-exact-active {
          color: $color-accent;
          //border-left: 4px solid $color-gray ;
          //border-right: 4px solid $color-gray ;
        }
        &:hover {
          background: #eee;
          //border-left: 4px solid $color-gray ;
          //border-right: 4px solid $color-accent ;
          //color: #fff ;
        }
      }
    }
    &.on {
      display: block;
    }
  }
}
.dropme-enter-active,
.dropme-leave-active {
  transition: opacity 0.1s;
}

.dropme-enter, .dropme-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}

.menuicon {
  display: inline-block;
  cursor: pointer;

  & > .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333333;
    margin: 6px 0;
    transition: 0.4s;
  }

  & > .bar1 {
    &.change {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
      background-color: #00b2ee;
    }
  }

  & .bar2 {
    &.change {
      opacity: 0;
    }
  }

  & .bar3 {
    &.change {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
      background-color: #00b2ee;
    }
  }
}
</style>
