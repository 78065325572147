import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../views/HomePage'
import About from '../views/About'
import Dashboard from '../views/Dashboard'
import Accounts from '../views/Accounts'
import Profile from '../views/Profile'
import LoginPage from '../views/LoginPage'
import VerifyPage from '../views/VerifyPage'
import GetVerificationID from '../views/GetVerificationID'
import RegisterPage from '../views/RegisterPage'

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'home', component: HomePage },
    { path: '/about', name: 'about', component: About },
    { path: '/login', name: 'login', component: LoginPage },
    { path: '/verify', name: 'verify', component: VerifyPage },
    { path: '/resend-verification-id', name: 'resend-verification-id', component: GetVerificationID },
    { path: '/register', name: 'register', component: RegisterPage, props: true},
    { path: '/dashboard', name: 'dashboard', component: Dashboard },
    { path: '/accounts', name: 'accounts', component: Accounts },
    { path: '/profile', name: 'profile', component: Profile },

    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/', '/about', '/login', '/register', '/verify', '/resend-verification-id'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next({ 
      path: '/login', 
      query: { returnUrl: to.path } 
    });
  }

  next();
})
